.appLogo {
    height: 60px;
}

.appHeader {
    padding: 0;
    display: flex;
    align-items: center;
    z-index: 9999;
}

.header {
    z-index: 100;
    background: #FFFFFF;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    display: flex;
    padding-right: 10px;
    padding-left: 20px;
    flex-direction: row;
    align-items: center;
}

.pathTitle {
    flex: 1;
    margin: 0 20px;
}

.background {
    z-index: -200;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.appTitle {
    font-size: 28px;
    font-weight: 600;
    color: white;
    letter-spacing: 1px;
}

.appDescription {
    font-size: 18px;
    font-weight: 300;
    color: white;
    letter-spacing: 3px;
}

.loginContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
}

.loadingContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.appContent {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    padding: 0 18px;
}

.appHeaderPlacer {
    visibility: hidden;
    margin-bottom: 18px;
}

.appFooter {
    text-align: center;
    font-size: 10px;
    opacity: 0.4;
}