.background {
  background: #FFFFFF;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  width: 320px;
  padding: 20px 32px 36px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #444444;
  align-self: center;
}

.button {
  font-size: 16px;
  font-weight: 600;
  min-height: 32px;
  color: #FFFFFF;
  margin-top: 20px;
}

.verifyCode {
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.inputColumn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
}
